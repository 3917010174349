<template>
  <div class="noticeWrap main">
    <h2 class="mb15">校园公告</h2>
    <div class="noData" v-if="noticeList.length == 0">
      <img src="./assets/images/noData.png" alt="" />
      <p>暂无公告</p>
    </div>
    <div v-else class="noticeListBox">
      <ul>
        <li
          v-for="(item, index) in noticeList"
          :key="index"
          @click="jumpDetail(item)"
        >
          <i class="mr15" :class="item.top == 1 ? 'goldDot' : 'grayDot'"></i>
          <div class="mainNotice">
            <div>
              <img
                src="./assets/images/top.png"
                alt=""
                v-if="item.top == 1"
                class="mr5"
              />
              <span class="noticeName">{{ item.notice_name }}</span>
            </div>
            <span class="makeTime">{{ item.make_time }}</span>
          </div>
        </li>
      </ul>
      <p class="moreBtn" @click="getMore">
        <i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import { toNoticeList } from "./assets/api";
export default {
  data() {
    return {
      page: 1,
      btnMessage: "加载更多",
      noticeList: [],
    };
  },
  mounted() {
    this.getNoticeList();
  },
  methods: {
    getNoticeList() {
      const params = {
        page: this.page,
      };
      toNoticeList(params).then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            this.noticeList = this.noticeList.concat(res.data);
          } else {
            this.btnMessage = "暂无更多";
          }
        } else {
          this.page--;
        }
      });
    },
    getMore() {
      if (this.btnMessage == "加载更多") {
        this.page++;
        this.getNoticeList();
      }
    },
    jumpDetail(item) {
      this.$router.push({
        path: "/notice_detail",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.noticeWrap {
  margin-top: 20px;
  background: #fff;
  border-radius: 8px;
  padding: 30px 24px;
  box-sizing: border-box;
  .noData {
    margin-top: 115px;
    margin-bottom: 150px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    color: #999;
    img {
      width: 88px;
      height: 92px;
      margin-bottom: 25px;
    }
  }
  .noticeListBox {
    li {
      display: flex;
      align-items: center;
      height: 60px;
      font-size: 16px;
      cursor: pointer;
      .goldDot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #ffab30;
      }
      .grayDot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #d5d5d5;
      }
      .mainNotice {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 60px;
        border-bottom: 1px dashed #e1e1e1;

        img {
          width: 36px;
          height: 20px;
        }
        .noticeName {
          &:hover {
            color: var(--default-color);
          }
        }
        .makeTime {
          color: #999;
        }
      }
    }
    .moreBtn {
      margin: 0 auto;
      margin-top: 30px;
      width: 100px;
      height: 30px;
      font-size: 14px;
      border-radius: 18px;
      border: 1px solid #e1e1e1;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      color: #666;
      &:hover{
        background:var(--change-color);
        border:1px solid var(--change-color);
        color:#fff;
      }
      i {
        font-size: 14px;
      }
    }
  }
}
</style>
